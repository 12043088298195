// components/Section.js
import React from "react";

const Section = ({ id, title, children }) => (
  <div id={id} className="section">
    <div className="header">
      <div className="header-container">{title}</div>
    </div>
    {children}
  </div>
);

export default Section;
