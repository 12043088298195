// components/Event.js
import React from "react";

const Event = ({
  appVersion,
  eventDate,
  eventLink,
  eventIsFriday,
  eventIsSpecial,
  eventIsBattlevox,
  specialLink,
  eventOver,
}) => {
  const renderRegularEvent = () => {
    return (
      <>
        <div className="event-container">
          <img
            src={`${appVersion}images/next_event.webp`}
            alt={eventDate}
            className="event-image"
          />
          <div className="event-info">
            <p>
              Get ready to unleash your inner rock star at KARAOKALYPSE, where
              the raw energy of rock legends past and present collides! Join us
              on <span className="event-details">{eventDate}</span>, downstairs
              in Fibber Magees for an unforgettable night of rock 'n' roll
              karaoke!{" "}
              {eventIsFriday ? (
                <>
                  That's right, <span className="event-details">FRIDAY</span>{" "}
                  instead! Put it in your calendar so you don't forget!
                </>
              ) : (
                <></>
              )}
            </p>
            <p>
              Whether you're a seasoned performer or a first-time singer, this
              is your chance to shine in Dublin's premier rock bar. The crowd is
              always welcoming and full of energy, cheering on every performance
              — so no matter what, you’ll feel like a rock legend on stage!{" "}
            </p>
            <p>
              Song dockets are handed out on a first come, first shred basis.
              Secure your spot early and make sure you don’t miss out on your
              moment in the spotlight! Rock history won’t wait, so get there
              early to claim your place on the stage.
            </p>
            <p>
              Entry is just €7 at the door,{" "}
              <a
                href={eventLink}
                className="event-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                visit our Facebook page
              </a>{" "}
              to mark your attendance and stay up to date with all the latest
              news. Don't miss your chance to be a part of the KARAOKALYPSE
              legend, we'll see you there!
            </p>
          </div>
        </div>
      </>
    );
  };

  const renderEventOver = () => {
    return (
      <>
        <div className="event-container">
          <img
            src={`${appVersion}images/event_over.jpg`}
            alt="Thanks for coming, stay tuned for next event details!"
            className="event-image"
          />
          <div className="event-info">
            <p>
              The stage lights may have dimmed, but the echo of your electric
              energy still reverberates through the heart of Dublin! Your rock
              spirit fueled <span className="event-details">KARAOKALYPSE</span>,
              making it a night etched in rock legend.
            </p>
            <p>
              Huge shoutout to all our rockstars who belted out anthems and
              shredded their hearts out. Your fervor was the real headliner, and
              the memories? They're platinum baby! The hangover though? Not such
              much!
            </p>
            <p>
              But remember, the best rock sagas have sequels.{" "}
              <span className="event-details">
                Stay plugged in here and on{" "}
                <a
                  href="https://www.facebook.com/Karaokalypse"
                  className="event-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>{" "}
                for news on our next big gig soon!
              </span>{" "}
            </p>
            <p>
              In the meantime, why not dive into our song list below and prep
              your vocal cords for the next show.
            </p>
            <p>Until next time, rock on!</p>
          </div>
        </div>
      </>
    );
  };

  const renderBattlevox = () => {
    return (
      <>
        <div className="event-container">
          <img
            src={`${appVersion}images/next_event.webp`}
            alt={eventDate}
            className="event-image"
          />
          <div className="event-info">
            <p>
              Unleash your inner rock god this Saturday at{" "}
              <span className="event-details">BATTLEVÖX</span>, a head-banging
              twist on our beloved{" "}
              <span className="event-details">KARAOKALYPSE</span>! Join us in
              the gritty depths of Fibbers basement on{" "}
              <span className="event-details">January 6th at 10PM</span> - and
              guess what? It's free entry!
            </p>
            <p>
              Prepare to step into a world where metal and melody collide, as
              singers challenge each other in a ferocious (but friendly!) battle
              of vocal prowess. Grab your chance to belt out tunes from an
              exclusive song list, and join up to six warriors in a vocal duel
              to score points for nailing those high-octane notes.
            </p>
            <p>
              <span className="event-details">BATTLEVÖX</span> is an arena where
              the spirit of rock thrives, perfect for seasoned Karaokalyps-ers
              and nervous novices alike. This is your chance to roar into the
              mic, embrace the mayhem, and let the crowd feel the power of your
              voice. So don your leather, spike up, and get ready for a night of
              wild, musical abandon. Remember,{" "}
              <span className="event-details">BATTLEVÖX</span> is all about the
              love of loud and live - where fun meets fierce in a symphony of
              chaos! Join us for a night of electrifying entertainment and let
              the rock revolution begin!
            </p>
            <p>
              For full details of the competition format, check out our{" "}
              <a
                href={eventLink}
                className="event-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              . See you all there!
            </p>
          </div>
        </div>
      </>
    );
  };

  const renderSpecialEvent = () => {
    return (
      <>
        <div className="event-container">
          <img
            src={`${appVersion}images/next_event.webp`}
            alt={eventDate}
            className="event-image"
          />
          <div className="event-info">
            <p>
              <span className="event-details">KARAOKALYPSE</span> is gearing up
              for an electrifying New Year's Eve special, this is one you won't
              want to miss!
            </p>
            <p>
              This coming December 31st, we're taking over downstairs Fibber
              Magees, not just for our legendary karaoke showdown but also for
              an epic night with <span className="event-details">TEMPEST</span>,
              the ultimate Alternative Rock, Metal, Industrial and Nu Metal
              night club, right from the turn of the new year until the last
              note fades at closing.
            </p>
            <p>
              Are you ready to be part of this legendary crossover? Get your
              vocal cords and dance moves ready; it's a first come, first shred
              (and groove) basis! Remember, rock and roll history waits for no
              one; spots for karaoke glory and dance floor dominance are first
              come, first served, so be there early to claim your place in this
              once-a-year musical extravaganza!
            </p>
            <p>
              Tickets are{" "}
              <a
                href={specialLink}
                className="event-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                available on Eventbrite
              </a>
              . Check out{" "}
              <a
                href={eventLink}
                className="event-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>{" "}
              for full details too. Get yours before they're gone!
            </p>
            <p>
              This is a New Year's Eve you'll never forget,{" "}
              <span className="event-details">KARAOKALYPSE and TEMPEST!!!</span>
            </p>
          </div>
        </div>
      </>
    );
  };

  if (eventOver) {
    return renderEventOver();
  } else if (eventIsSpecial) {
    return renderSpecialEvent();
  } else if (eventIsBattlevox) {
    return renderBattlevox();
  } else {
    return renderRegularEvent();
  }
};

export default Event;
