import Papa from "papaparse";

export function loadCSV(path, callback) {
  Papa.parse(path, {
    download: true,
    header: true,
    dynamicTyping: true,
    complete: (results) => {
      callback(results.data);
    },
  });
}

export function loadText(path, callback) {
  fetch(path)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch the text file");
      }
      return response.text();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("There was an error fetching the text file", error);
    });
}

export function loadJSON(path, callback) {
  fetch(path)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch the JSON file");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("There was an error fetching the JSON file", error);
    });
}
