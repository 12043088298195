// components/NavBar.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "react-responsive";
import { slide as Menu } from "react-burger-menu";

const NavBar = ({ appVersion }) => {
  const [isMenuOpen, setMenuOpen] = useState(false),
    isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const handleScrollAndCloseMenu = (link, animate) => {
    handleScroll(link);
    handleCloseMenu(animate);
  };

  const handleScroll = (id, animate) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: animate ? "smooth" : "instant",
      });
    }
  };

  const handleOpenLinkAndCloseMenu = (link) => {
    handleOpenLink(link);
    handleCloseMenu(false);
  };

  const handleOpenLink = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div id="outer-container-id">
      {isTabletOrMobile ? (
        <Menu
          noTransition
          width={"100%"}
          burgerBarClassName={isMenuOpen ? "is-open" : ""}
          itemListClassName="menu-list"
          isOpen={isMenuOpen}
          onStateChange={handleStateChange}
          pageWrapId="page-wrap"
          outerContainerId="outer-container-id"
        >
          <button
            className="link"
            onClick={() => handleScrollAndCloseMenu("next-event", false)}
          >
            Next Event
          </button>
          <button
            className="link"
            onClick={() => handleScrollAndCloseMenu("song-list", false)}
          >
            Song List
          </button>
          <button
            className="link"
            onClick={() =>
              handleOpenLinkAndCloseMenu(
                "https://www.facebook.com/Karaokalypse"
              )
            }
          >
            Facebook
          </button>
          <button
            className="link"
            onClick={() =>
              handleOpenLinkAndCloseMenu(
                "https://open.spotify.com/playlist/6054Si0YLZOeoqqPVwMjPg?si=b307d488ddb94d25"
              )
            }
          >
            Spotify
          </button>
          <button
            className="link"
            onClick={() =>
              handleOpenLinkAndCloseMenu("http://www.fibbermagees.ie", true)
            }
          >
            Fibber Magees
          </button>
        </Menu>
      ) : null}
      <div className={`navbar ${isTabletOrMobile ? "logo-only" : ""}`}>
        {!isTabletOrMobile ? (
          <div className="links">
            <button
              className="link"
              onClick={() => handleScroll("next-event", true)}
            >
              Next Event
            </button>
            <button
              className="link"
              onClick={() => handleScroll("song-list", true)}
            >
              Song List
            </button>
          </div>
        ) : null}
        <div className="logo">
          <img
            className="logo-img"
            src={`${appVersion}images/logo.png`}
            alt="Karaokalypse"
          />
        </div>
        {!isTabletOrMobile ? (
          <div className="links">
            <button
              className="link"
              onClick={() => handleOpenLink("http://www.fibbermagees.ie", true)}
            >
              Fibber Magees
            </button>
            <FontAwesomeIcon
              className="link-icon"
              icon={faFacebook}
              size="2x"
              onClick={() =>
                handleOpenLink("https://www.facebook.com/Karaokalypse")
              }
            />
            <FontAwesomeIcon
              className="link-icon"
              icon={faSpotify}
              size="2x"
              onClick={() =>
                handleOpenLink(
                  "https://open.spotify.com/playlist/6054Si0YLZOeoqqPVwMjPg?si=b307d488ddb94d25"
                )
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NavBar;
